import * as React from 'react';
import type { SVGProps } from 'react';
const SvgShowNavigation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path stroke="currentColor" strokeLinejoin="round" strokeWidth={1.44} d="M2 2h16v16H2z" />
    <path stroke="currentColor" strokeWidth={1.44} d="M14.72 2v16" />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="m7.402 7.5 2.651 2.651-2.651 2.652"
    />
  </svg>
);
export default SvgShowNavigation;
