import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCommunications = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.44}
      d="M1.5 3.135h17v13.73h-17z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.44}
      d="m1.5 4.79 7.657 6.46a1.31 1.31 0 0 0 1.687 0L18.5 4.79"
    />
  </svg>
);
export default SvgCommunications;
