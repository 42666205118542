import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g clipPath="url(#Settings_svg__a)">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.44}
        d="m7.472 3.214.614-1.586A1.43 1.43 0 0 1 9.415.714h1.17a1.43 1.43 0 0 1 1.33.914l.614 1.586 2.085 1.2 1.686-.257a1.43 1.43 0 0 1 1.429.7l.571 1a1.43 1.43 0 0 1-.114 1.614L17.143 8.8v2.4l1.072 1.328a1.43 1.43 0 0 1 .114 1.614l-.572 1a1.43 1.43 0 0 1-1.428.7l-1.686-.257-2.086 1.2-.614 1.586a1.43 1.43 0 0 1-1.328.914h-1.2a1.43 1.43 0 0 1-1.329-.914l-.614-1.586-2.086-1.2-1.686.257a1.43 1.43 0 0 1-1.428-.7l-.572-1a1.43 1.43 0 0 1 .115-1.614L2.857 11.2V8.8L1.786 7.47a1.43 1.43 0 0 1-.114-1.614l.571-1a1.43 1.43 0 0 1 1.429-.7l1.685.257zM7.143 10a2.857 2.857 0 1 0 5.714 0 2.857 2.857 0 0 0-5.714 0"
      />
    </g>
    <defs>
      <clipPath id="Settings_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSettings;
