import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHelp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g clipPath="url(#Help_svg__a)">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.44}
        d="M10 19.285A9.286 9.286 0 1 0 10 .714a9.286 9.286 0 0 0 0 18.571"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.44}
        d="M7.856 7.857A2.143 2.143 0 1 1 10 10v1.428"
      />
      <path
        fill="currentColor"
        d="M10 13.571a1.07 1.07 0 1 0 1.07 1.072A1.086 1.086 0 0 0 10 13.57"
      />
    </g>
    <defs>
      <clipPath id="Help_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHelp;
