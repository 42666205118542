import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReports = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.44}
      clipPath="url(#Reports_svg__a)"
    >
      <path d="M1.5 18.499h17M6.73 18.5v-17H1.5v17M11.961 18.5V9.345h-5.23v9.153M17.193 18.5V5.422h-5.231v13.076" />
    </g>
    <defs>
      <clipPath id="Reports_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgReports;
