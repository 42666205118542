import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';

const colorOptions = {
  red: 'bg-red-100 text-red-700',
  orange: 'bg-orange-100 text-orange-700',
  yellow: 'bg-yellow-100 text-yellow-700',
  green: 'bg-green-100 text-green-700',
  teal: 'bg-teal-100 text-teal-700',
  blue: 'bg-blue-100 text-blue-700',
  indigo: 'bg-indigo-100 text-indigo-700',
  purple: 'bg-purple-100 text-purple-700',
  gray: 'bg-gray-100 text-gray-700',
} as const;

const avatarStyles = cva('inline-flex items-center justify-center rounded-full flex-shrink-0', {
  variants: {
    size: {
      '6': 'h-6 w-6 text-xs',
      '8': 'h-8 w-8 text-xs',
      '10': 'h-10 w-10 text-sm',
    },
    color: colorOptions,
  },
  defaultVariants: {
    size: '8',
    color: 'gray',
  },
});

type AvatarProps = VariantProps<typeof avatarStyles>;

/**
 * Gets a consistent color option based on the input name.
 * @param {string} name - The name to generate a color for.
 * @returns {string[]} An array containing two strings: background color class and text color class.
 * @example
 * // Returns ['bg-blue-500', 'text-blue-50']
 * getConsistentColorOption('John Doe')
 * @example
 * // Returns ['bg-purple-500', 'text-purple-50']
 * getConsistentColorOption('Jane Smith')
 */
function getConsistentColorOption(name: string): AvatarProps['color'] {
  const hash = name.split('').reduce((acc, char) => {
    // Use char code and bitwise operations for a simple but effective hash
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);

  // Use the hash to select a consistent color option
  // Math.abs ensures we have a positive index
  const options = Object.keys(colorOptions);
  const index = Math.abs(hash) % options.length;
  return (options[index] as AvatarProps['color']) ?? 'gray';
}

/**
 * A circular avatar component.
 */
export function Avatar({
  name,
  size,
  color: customColor,
  className,
}: {
  name?: string;
  className?: string;
} & AvatarProps) {
  const initials = name
    ?.split(' ')
    .map((n) => n?.[0])
    .join('')
    .slice(0, 2);
  const color = customColor ?? getConsistentColorOption(name ?? '');
  return (
    <div className={clsx(avatarStyles({ size, color }), className)}>
      <span className="font-semibold leading-none">{initials}</span>
    </div>
  );
}
