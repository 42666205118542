import clsx from 'clsx';
import { motion } from 'framer-motion';
import Link from 'next/link';

type Props = {
  isCollapsed: boolean;
};

export function AnimatedSolvLogo({ isCollapsed }: Props) {
  return (
    <Link href="/hello">
      <a className={clsx('flex transition-all', isCollapsed ? 'justify-start' : 'px-3')}>
        <div className="relative flex items-baseline">
          <motion.svg
            animate={{
              x: isCollapsed ? '-100%' : 0,
              opacity: isCollapsed ? 0 : 1,
              scale: isCollapsed ? 0.8 : 1,
            }}
            initial={false}
            style={{ originX: 0, originY: 1 }}
            className="origin-bottom-left"
            width="81"
            height="32"
            viewBox="0 0 81 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.5594 9.6C21.9425 5.24444 19.0345 0 11.7203 0C6.08045 0 1.23371 4.17778 1.23371 9.6C1.23371 14.2222 4.318 17.4222 9.16474 18.4L13.3946 19.2889C15.7739 19.7333 17.0958 21.1556 17.0958 22.9333C17.0958 25.0667 15.3333 26.6667 12.1609 26.6667C7.93102 26.6667 5.81608 23.9111 5.55172 20.8889H0C0.70498 26.6667 4.05363 32 12.0728 32C19.1226 32 23 27.2 23 22.4889C23 18.1333 20.0919 14.4889 14.5402 13.4222L10.3103 12.6222C8.10727 12.1778 7.0498 10.8444 7.0498 9.15556C7.0498 7.11111 8.90037 5.24444 11.8084 5.24444C15.5977 5.24444 17.0076 7.91111 17.272 9.6H22.5594ZM35.6896 26.7556C32.9578 26.7556 30.4023 24.7111 30.4023 20.7111C30.4023 16.7111 32.9578 14.6667 35.6896 14.6667C38.4214 14.6667 40.9769 16.7111 40.9769 20.7111C40.9769 24.7111 38.4214 26.7556 35.6896 26.7556ZM35.564 9.42222C29.3073 9.42222 24.5487 14.1333 24.5487 20.7111C24.5487 27.2 29.3073 32 35.564 32C41.8207 32 46.5793 27.2 46.5793 20.7111C46.4912 14.1333 41.8207 9.42222 35.564 9.42222ZM56.1846 31.4667H53.4528C49.5754 31.4667 49.2229 28.1778 49.2229 24.9778V0H54.8628V12.5333V25.0667C54.8628 25.0667 54.6865 26.3111 55.8321 26.3111H58.2996L60.0765 31.4667H56.1846ZM80.4183 10.0444L72.311 31.2889H66.6712L74.426 10.0444H80.4183Z"
              fill="#3C3C3C"
            />
            <path
              d="M69.4402 23.8217L64.3291 9.95508H58.0724L66.6203 31.3773L69.4402 23.8217Z"
              fill="url(#paint0_linear_1_2)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1_2"
                x1="63.3037"
                y1="15.9632"
                x2="73.3194"
                y2="22.6118"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#3C3C3C" />
                <stop offset="1" stopColor="#C9C9C9" />
              </linearGradient>
            </defs>
          </motion.svg>

          <motion.div
            className="w-2 h-2 bg-[#F21887] rounded-full flex items-center justify-center absolute"
            initial={false}
            animate={{
              x: isCollapsed ? 6 : 77,
              y: isCollapsed ? 6 : 23,
              ...(isCollapsed ? { width: 36, height: 36 } : { width: 8, height: 8 }),
            }}
          >
            <motion.svg
              initial={false}
              animate={{ scale: isCollapsed ? 1 : 0 }}
              width="16"
              height="21"
              viewBox="0 0 16 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.13798 0.0999756C12.7173 0.0999756 14.538 3.49247 14.9242 6.30997H11.6138C11.4483 5.21747 10.5656 3.49248 8.19315 3.49248C6.37246 3.49248 5.21384 4.69998 5.21384 6.02248C5.21384 7.11497 5.87591 7.97747 7.25522 8.26497L9.9035 8.78247C13.3794 9.47247 15.2 11.83 15.2 14.6475C15.2 17.695 12.7725 20.8 8.35867 20.8C3.33798 20.8 1.24143 17.35 0.800049 13.6125H4.27591C4.44143 15.5675 5.76557 17.35 8.41384 17.35C10.4 17.35 11.5035 16.315 11.5035 14.935C11.5035 13.785 10.6759 12.865 9.18625 12.5775L6.53798 12.0025C3.5035 11.37 1.57246 9.29997 1.57246 6.30997C1.57246 2.80247 4.60695 0.0999756 8.13798 0.0999756Z"
                fill="white"
              />
            </motion.svg>
          </motion.div>
        </div>
      </a>
    </Link>
  );
}
