import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCallCenter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.44}
      clipPath="url(#Call_Center_svg__a)"
    >
      <path d="M4.77 10V6.563A5.14 5.14 0 0 1 10 1.5v0a5.14 5.14 0 0 1 5.231 5.06v3.44M2.808 8.04h1.307a.654.654 0 0 1 .654.653v3.923a.654.654 0 0 1-.654.654H2.808A1.31 1.31 0 0 1 1.5 11.962V9.347a1.31 1.31 0 0 1 1.308-1.308M17.192 13.27h-1.307a.654.654 0 0 1-.654-.653V8.694a.654.654 0 0 1 .654-.654h1.307A1.31 1.31 0 0 1 18.5 9.347v2.616a1.31 1.31 0 0 1-1.308 1.308" />
      <path d="M12.615 16.866a2.615 2.615 0 0 0 2.616-2.615v-2.942" />
      <path d="M12.616 16.866a1.634 1.634 0 0 1-1.635 1.635H9.019a1.634 1.634 0 1 1 0-3.27h1.962a1.635 1.635 0 0 1 1.635 1.635" />
    </g>
    <defs>
      <clipPath id="Call_Center_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCallCenter;
