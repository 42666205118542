import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReviews = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.44}
      d="m10.66 2.259 2.084 4.208a.67.67 0 0 0 .538.393l4.6.682a.708.708 0 0 1 .394 1.219l-3.316 3.29a.7.7 0 0 0-.21.629l.8 4.627a.72.72 0 0 1-1.05.76l-4.141-2.189a.77.77 0 0 0-.682 0l-4.142 2.19a.72.72 0 0 1-1.049-.761l.8-4.68a.7.7 0 0 0-.21-.629L1.72 8.761a.708.708 0 0 1 .433-1.22l4.601-.681a.67.67 0 0 0 .537-.393l2.085-4.208a.708.708 0 0 1 1.284 0"
    />
  </svg>
);
export default SvgReviews;
