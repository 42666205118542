import { Modal } from 'antd';
import styled from 'styled-components';
import { getBooleanFlag } from '../../core/flags/flags';
import { isLoggedIn } from '../../core/session';
import { useLogin } from '../../hooks/useLogin';
import LogOut from '../LogOut/LogOut';
import { isInternetExplorer } from '../Queue/util';

const StyledModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 32px;
  width: 356px;
`;

const StyledModalCopyContainer = styled.div`
  color: #909090;
  font-family: 'Circular-Pro-Book-S';
  font-size: 14px;
  line-height: 17.71px;
`;

const StyledModalHeader = styled.label`
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-bottom: 8px;
  color: #000000;
  font-family: 'Circular-Pro-Medium-S';
`;

const StyledLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const StyledBrowserLink = styled.a`
  width: calc(50% - 5px);
  padding: 10px 14px;
  border: 1px solid #c6cacf;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #4d4d4d;
`;

const StyledBrowserLabel = styled.p`
  color: #4d4d4d;
  font-weight: 700;
  font-size: 16px;
  display: block;
  margin: 0;
  font-family: Inter, sans-serif;
`;

const StyledBrowserIcon = styled.img`
  width: 28px;
  height: 28px;
  margin-right: 16px;
`;

export function IeWarning() {
  const shouldNotSupportInternetExplorer = getBooleanFlag('end-internet-explorer-11-support');
  const login = useLogin();
  const shouldLogOut = isLoggedIn(login);

  return isInternetExplorer() && shouldNotSupportInternetExplorer ? (
    <>
      <Modal closable={false} visible footer={null} centered width={460}>
        <StyledModalContent>
          <img src="/images/icons/warning.svg" alt="warning icon" />
          <StyledModalCopyContainer>
            <StyledModalHeader>Internet Explorer 11 is no longer supported</StyledModalHeader>
            We no longer support Internet Explorer 11, as it has been officially deprecated by
            Microsoft and is no longer a safe browser to use.
            <br />
            <br />
            Please use one of the following browsers below to continue.
          </StyledModalCopyContainer>
        </StyledModalContent>
        <StyledLinksContainer>
          <StyledBrowserLink href="https://www.google.com/chrome/" target="_blank">
            <StyledBrowserIcon alt="chrome icon" src="/images/icons/browser/chrome.png" />
            <StyledBrowserLabel>Google Chrome</StyledBrowserLabel>
          </StyledBrowserLink>
          <StyledBrowserLink href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">
            <StyledBrowserIcon alt="firefox icon" src="/images/icons/browser/firefox.png" />
            <StyledBrowserLabel>Mozilla Firefox</StyledBrowserLabel>
          </StyledBrowserLink>
          <StyledBrowserLink href="https://support.apple.com/downloads/safari" target="_blank">
            <StyledBrowserIcon alt="safari icon" src="/images/icons/browser/safari.png" />
            <StyledBrowserLabel>Safari</StyledBrowserLabel>
          </StyledBrowserLink>
          <StyledBrowserLink
            href="https://www.microsoft.com/en-us/edge/download?form=MA13FJ"
            target="_blank"
          >
            <StyledBrowserIcon alt="edge icon" src="/images/icons/browser/edge.png" />
            <StyledBrowserLabel>Microsoft Edge</StyledBrowserLabel>
          </StyledBrowserLink>
        </StyledLinksContainer>
      </Modal>
      {shouldLogOut && <LogOut />}
    </>
  ) : null;
}
