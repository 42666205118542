import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPatients = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.44}
      clipPath="url(#Patients_svg__a)"
    >
      <path d="M15.23 6.73a2.615 2.615 0 1 0 0-5.23 2.615 2.615 0 0 0 0 5.23M15.231 9.346c-3.27 0-3.27 9.154-3.27 9.154h6.54s0-9.154-3.27-9.154M4.769 6.73a2.615 2.615 0 1 0 0-5.23 2.615 2.615 0 0 0 0 5.23M4.77 18.5C1.5 18.5 1.5 9.346 1.5 9.346h6.539s0 9.154-3.27 9.154" />
    </g>
    <defs>
      <clipPath id="Patients_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPatients;
