import {
  autoUpdate,
  FloatingPortal,
  useDelayGroup,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import { offset, shift, flip } from '@floating-ui/react-dom';
import React, { useState } from 'react';

/*
 * This is a tooltip built specifically for the left main navigation.
 * We've opted to use Floating UI instead of the default radix/shadcn tooltip
 * as it provides more flexibility and control over the tooltip behavior.
 * Floating UI allows us to easily handle switching between multiple tooltips
 * as you hover over nav items, with smooth transitions and precise positioning.
 * It offers a powerful set of primitives that we can customize to perfectly fit
 * our navigation tooltip requirements.
 */

interface AnimatedTooltipProps {
  children: React.ReactElement;
  label: React.ReactNode;
  'aria-label'?: string;
}

export function NavTooltip({ children, label, 'aria-label': ariaLabel }: AnimatedTooltipProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: 'right',
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(16), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const { delay } = useDelayGroup(context);
  const hover = useHover(context, { move: false, delay });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, {
    role: 'label',
  });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

  const { isMounted, styles } = useTransitionStyles(context, {
    initial: { opacity: 0, scale: 0.8, marginLeft: '4px' },
    open: { opacity: 1, scale: 1, marginLeft: '0px' },
    close: { opacity: 0, scale: 0.8, marginLeft: '4px' },
    duration: {
      open: 75,
      close: 150,
    },
  });

  return (
    <div className="relative">
      {React.cloneElement(children, { ref: refs.setReference, ...getReferenceProps() })}

      <FloatingPortal>
        {isMounted && (
          <div
            ref={refs.setFloating}
            {...getFloatingProps()}
            style={{ ...floatingStyles, ...styles }}
            className="bg-white appearance-none border-gray-200 text-gray-800 font-sans text-xs shadow-md shadow-gray-200/50 rounded-md p-2 border !border-gray-100/50 "
            aria-label={ariaLabel}
          >
            {label}
          </div>
        )}
      </FloatingPortal>
    </div>
  );
}
