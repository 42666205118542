import * as React from 'react';
import type { SVGProps } from 'react';
const SvgQueue = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.44}
      clipPath="url(#Queue_svg__a)"
    >
      <path d="M2.808 4.115A1.31 1.31 0 0 0 1.5 5.423v11.77A1.307 1.307 0 0 0 2.808 18.5h14.384a1.307 1.307 0 0 0 1.308-1.308V5.422a1.307 1.307 0 0 0-1.308-1.307h-2.615M5.424 1.5v5.23M14.577 1.5v5.23M5.424 4.115h6.538M13.269 13.923H6.73M1.5 9.346h17" />
    </g>
    <defs>
      <clipPath id="Queue_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgQueue;
