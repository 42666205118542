import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { NavTooltip } from '../NavTooltip';

// just the styling with no interactivity
function NavLinkBase({
  children,
  icon: Icon,
  isActive,
  isPending,
}: {
  children: string;
  icon: React.ElementType;
  isActive?: boolean;
  isPending?: boolean;
}) {
  return (
    <div
      className={clsx(
        'text-sm font-medium flex items-center gap-6 py-3',
        ' rounded-lg transition-all duration-75 cursor-pointer px-[14px]',
        'truncate h-10 justify-start',
        {
          'bg-pink-50 !text-pink-600 visited:!text-pink-600 hover:bg-pink-50/50': isActive,
          'bg-gray-50': isPending && !isActive,
          '!text-gray-900 visited:!text-gray-900 hover:bg-gray-50': !isActive && !isPending,
        }
      )}
    >
      {/* Stroke width 1.7 makes the off-the-shelf lucide icons we pass match the custom ones from figma better */}
      <Icon width={18} height={18} className="flex-shrink-0" strokeWidth={1.7} />
      {children}
    </div>
  );
}

export function SideNavLink({
  href,
  onClick,
  children,
  icon: Icon,
  collapsed,
  matchPath,
  tooltipLabel,
  gate,
  external,
}: {
  href?: string;
  onClick?: () => void;
  children: string;
  icon: React.ElementType;
  collapsed: boolean;
  matchPath?: string;
  tooltipLabel?: string;
  gate?: boolean;
  external?: boolean;
}) {
  const router = useRouter();
  let Component: JSX.Element | null = null;

  if (typeof gate === 'boolean' && !gate) return null;

  if (href) {
    const pathname = router.pathname;
    let hrefPath = href; // if href is just a path (no domain), use it as is
    try {
      // in case its a full URL, try to safely extract the pathname
      hrefPath = new URL(href).pathname;
    } catch (e) {
      // do nothing
    }

    const isActive =
      !!(matchPath && pathname.startsWith(matchPath)) || // if we set a custom match path use it
      !!(hrefPath && !external && pathname.startsWith(hrefPath)); // otherwise try to match internal links
    if (external) {
      Component = (
        <a href={href}>
          <NavLinkBase icon={Icon} isActive={isActive}>
            {children}
          </NavLinkBase>
        </a>
      );
    } else {
      Component = (
        <Link href={href}>
          <a>
            <NavLinkBase icon={Icon} isActive={isActive}>
              {children}
            </NavLinkBase>
          </a>
        </Link>
      );
    }
  } else {
    Component = (
      <button onClick={onClick} className="block w-full">
        <NavLinkBase icon={Icon}>{children}</NavLinkBase>
      </button>
    );
  }

  if (collapsed) {
    Component = (
      <NavTooltip label={tooltipLabel ?? children}>
        <div>{Component}</div>
      </NavTooltip>
    );
  }

  return Component;
}
